import { ConfigService } from '../config/config.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../multi-countries/multi-countries.service";
var SharedNewService = /** @class */ (function () {
    function SharedNewService(configService, multiCountriesService) {
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
    }
    SharedNewService.prototype.showQtyLeftMsg = function (product, index) {
        if (index === void 0) { index = 0; }
        var qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        var quantity = 0;
        quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    };
    SharedNewService.prototype.showPriceListQtyLeftMsg = function (quantity) {
        var qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    };
    SharedNewService.prototype.getPriceBasedOnExhangeRate = function (price) {
        if (this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
            var toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
            var fromRate = this.multiCountriesService.rates['INR'];
            return ((toRate / fromRate) * price);
        }
        else {
            return price;
        }
    };
    SharedNewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedNewService_Factory() { return new SharedNewService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.MultiCountriesService)); }, token: SharedNewService, providedIn: "root" });
    return SharedNewService;
}());
export { SharedNewService };
